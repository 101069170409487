'use client'

import { Checkbox } from '~/core/ui/Checkbox'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn, getDisabledSelectedCondition } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import { useCallback, useMemo } from 'react'

const choiceChipVariants = cva(
  'flex flex-wrap justify-center items-center cursor-pointer rounded border border-solid bg-white dark:bg-gray-900',
  {
    variants: {
      variant: {
        6: 'pl-4',
        5: 'pl-3.5',
        4: 'pl-3',
        3: 'pl-2.5',
        2: 'pl-2'
      },
      size: {
        lg: 'pr-4 py-[9px] min-h-[42px]',
        md: 'pr-3.5 py-[9px] min-h-[38px]',
        sm: 'pr-3 py-1.5 min-h-[32px]',
        xs: 'pr-2.5 py-[3px] min-h-[24px]'
      },
      selectDisabled: {
        noDisabledSelected:
          'border-gray-300 dark:border-gray-600 hover:bg-gray-50 focus:ring-1 focus:ring-primary-300 dark:hover:bg-gray-800 dark:focus:ring-primary-700',
        noDisabledWithSelected:
          'border-primary-400 hover:bg-gray-50 focus:ring-1 focus:ring-primary-300 dark:hover:bg-gray-800 dark:focus:ring-primary-700',
        disabledNoSelect:
          'pointer-events-none border-gray-200 dark:border-gray-700',
        disabledWithSelect:
          'pointer-events-none border-primary-200 dark:border-primary-800'
      }
    },
    defaultVariants: {
      variant: 6,
      size: 'lg',
      selectDisabled: 'noDisabledSelected'
    }
  }
)

const choiceChipLabelVariants = cva('', {
  variants: {
    size: {
      lg: 'text-base',
      md: 'text-sm',
      sm: 'text-sm',
      xs: 'text-xs'
    },
    variant: {
      noDisabledSelected: 'text-gray-600 dark:text-gray-300',
      noDisabledWithSelected: 'text-primary-400 dark:text-primary-400',
      disabledNoSelect: 'text-gray-400 dark:text-gray-600',
      disabledWithSelect: 'text-primary-200 dark:text-primary-800'
    }
  },
  defaultVariants: {
    size: 'lg',
    variant: 'noDisabledSelected'
  }
})

const choiceChipIconVariants = cva('', {
  variants: {
    size: {
      lg: 'mr-2',
      md: 'mr-1.5',
      sm: 'mr-1.5',
      xs: 'mr-1'
    },
    variant: {
      noDisabledSelected: 'text-gray-500 dark:text-gray-400',
      noDisabledWithSelected: 'text-primary-400 dark:text-primary-400',
      disabledNoSelect: 'text-gray-400 dark:text-gray-600',
      disabledWithSelect: 'text-primary-200 dark:text-primary-800'
    }
  },
  defaultVariants: {
    size: 'lg',
    variant: 'noDisabledSelected'
  }
})

const choiceChipDefaultSizeIcon: { [key: string]: number } = {
  lg: 18,
  md: 16,
  sm: 16,
  xs: 14
}

const getChoiceChipPaddingCondition = ({
  size = 'lg',
  selectOption = 'radio'
}: {
  size?: 'lg' | 'md' | 'sm' | 'xs'
  selectOption?: 'radio' | 'checkbox'
}) => {
  if (size === 'lg' && selectOption === 'radio') return 6

  if (
    (size === 'lg' && selectOption === 'checkbox') ||
    (size === 'md' && selectOption === 'radio')
  ) {
    return 5
  }

  if (
    (size === 'md' && selectOption === 'checkbox') ||
    (size === 'md' && selectOption === 'radio') ||
    (size === 'sm' && selectOption === 'radio')
  ) {
    return 4
  }

  if (size === 'xs' && selectOption === 'radio') return 3

  if (['s', 'xs'].includes(size) && selectOption === 'checkbox') return 2

  return 6
}

interface ChoiceChipsGeneralProps {
  size?: 'lg' | 'md' | 'sm' | 'xs'
  selectOption?: 'radio' | 'checkbox'
  isDisabled?: boolean
  iconMenus?: LucideIconName 
  selectedConfig?: {
    borderColorClassName?: string
    backgroundColorClassName?: string
    textColorClassName?: string
  }
}

type ChoiceChipsProps = ChoiceChipsGeneralProps & {
  label: string
  value: string | number
  isSelected?: boolean
  onItemToggle?: ({
    itemValue,
    isChecked
  }: {
    itemValue: string | number
    isChecked: boolean
  }) => void
}

type ChoiceChipsSelectProps = ChoiceChipsGeneralProps & {
  source: Array<ChoiceChipsProps>
  value?: Array<string | number> | string | number
  isMultiple?: boolean
  onChange: (value: Array<string | number>) => void
}

const renderChoiceId = ({ value }: { value: string | number }) =>
  `choice-chip-${String(value).trim().toLowerCase().replace(/\s/g, '-')}`

const ChoiceChipsUI = ({
  size = 'lg',
  label,
  value,
  selectOption = 'radio',
  isDisabled = false,
  isSelected = false,
  iconMenus,
  selectedConfig = {
    borderColorClassName: '',
    backgroundColorClassName: '',
    textColorClassName: ''
  },
  onItemToggle
}: ChoiceChipsProps) => {
  return (
    <>
      <Checkbox
        id={renderChoiceId({ value })}
        iconOnly
        className="hidden"
        isChecked={isSelected}
        onCheckedChange={({ target: { checked } }) => {
          onItemToggle && onItemToggle({ itemValue: value, isChecked: checked })
        }}
      />

      <label
        className={`${cn(
          choiceChipVariants({
            variant: getChoiceChipPaddingCondition({ size, selectOption }),
            size,
            selectDisabled: getDisabledSelectedCondition({
              isDisabled,
              isSelected
            })
          })
        )} ${
          isSelected &&
          (!!selectedConfig.backgroundColorClassName ||
            !!selectedConfig.borderColorClassName)
            ? `${selectedConfig.backgroundColorClassName} ${selectedConfig.borderColorClassName}`
            : ''
        }`}
        htmlFor={renderChoiceId({ value })}>
        <>
          {selectOption === 'checkbox' &&
            (isSelected ? (
              <span
                className={cn(
                  choiceChipIconVariants({
                    size,
                    variant: getDisabledSelectedCondition({
                      isDisabled,
                      isSelected
                    })
                  })
                )}>
                <IconWrapper
                  className={
                    isSelected && !!selectedConfig.textColorClassName
                      ? selectedConfig.textColorClassName
                      : ''
                  }
                  name={iconMenus ? iconMenus : 'Check'}
                  size={choiceChipDefaultSizeIcon[size]}
                />
              </span>
            ) : (
              <span
                className={`${cn(
                  choiceChipIconVariants({
                    size,
                    variant: getDisabledSelectedCondition({
                      isDisabled,
                      isSelected
                    })
                  })
                )} `}>
                <IconWrapper
                  name={iconMenus ? iconMenus : 'Plus'}
                  size={choiceChipDefaultSizeIcon[size]}
                />
              </span>
            ))}
          <span
            className={`${cn(
              choiceChipLabelVariants({
                size,
                variant: getDisabledSelectedCondition({
                  isDisabled,
                  isSelected
                })
              })
            )} ${
              isSelected && !!selectedConfig.textColorClassName
                ? selectedConfig.textColorClassName
                : ''
            }`}>
            {label}
          </span>
        </>
      </label>
    </>
  )
}

const ChoiceChips = ({
  source = [],
  value,
  onChange,
  isMultiple,
  ...otherProps
}: ChoiceChipsSelectProps) => {
  const data = useMemo(
    () =>
      Array.isArray(value) ? value : typeof value === 'string' ? [value] : [],
    [value]
  )

  const onItemToggle = useCallback(
    ({
      itemValue,
      isChecked
    }: {
      itemValue: string | number
      isChecked: boolean
    }) => {
      if (isMultiple) {
        const isItemExistInData = !!data.find(
          (item) => String(item) === itemValue
        )

        if (isChecked && !isItemExistInData) onChange([...data, itemValue])
        else if (!isChecked && isItemExistInData)
          onChange(data.filter((item) => String(item) !== itemValue))
      } else onChange([itemValue])
    },
    [onChange, data, isMultiple]
  )

  return (
    <div className="flex">
      {source.map((choiceProps, index) => (
        <div key={`choice-chips-${index}`} className="mr-2 last:mr-0">
          <ChoiceChipsUI
            {...otherProps}
            {...choiceProps}
            isSelected={
              !!data.find((item) => String(item) === choiceProps.value)
            }
            onItemToggle={onItemToggle}
          />
        </div>
      ))}
    </div>
  )
}

ChoiceChips.displayName = 'ChoiceChips'

export { ChoiceChips }
export type {
  ChoiceChipsGeneralProps,
  ChoiceChipsProps,
  ChoiceChipsSelectProps
}
