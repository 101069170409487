'use client'

import { cva } from 'class-variance-authority'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  forwardRef,
  useEffect,
  useRef
} from 'react'
import { Button } from '~/core/ui/Button'
import { AlertCircleFill } from '~/core/ui/FillIcons'
import { cn } from '~/core/ui/utils'

const textareaWithActionsWrapperVariants = cva('rounded', {
  variants: {
    variant: {
      unstyled: 'shadow-textarea border-t border-solid border-gray-100',
      outline:
        'dark:bg-gray-900 border border-solid border-gray-300 dark:border-gray-600 disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:border disabled:border-solid disabled:border-gray-200 dark:disabled:border-gray-700'
    },
    position: {
      top: 'top-0',
      bottom: 'bottom-0'
    },
    expand: {
      default: 'h-[48px]',
      expand: 'pb-[88px]'
    }
  },
  defaultVariants: {
    variant: 'outline',
    position: 'bottom',
    expand: 'default'
  }
})

const textareaWithActionsVariants = cva(
  'resize-none text-sm flex items-center justify-center w-full rounded placeholder:text-gray-500 dark:placeholder:text-gray-400 text-gray-900 dark:text-gray-200 disabled:placeholder:text-gray-400 dark:disabled:placeholder:text-gray-600 disabled:text-gray-400 dark:disabled:text-gray-600 outline-none',
  {
    variants: {
      expand: {
        default: 'h-[48px] p-3',
        expand: 'min-h-[59px] px-3 pt-3 pb-2'
      }
    },
    defaultVariants: {
      expand: 'default'
    }
  }
)

const textareaActionsVariants = cva(
  'absolute right-px z-20 flex h-[49px] items-center justify-between p-3',
  {
    variants: {
      position: {
        top: 'top-0',
        bottom: 'bottom-0'
      },
      expand: {
        default: '',
        expand: 'left-px border-t border-solid border-gray-100'
      }
    },
    defaultVariants: {
      position: 'bottom',
      expand: 'default'
    }
  }
)

interface TextAreaWithActionsProps {
  autoFocus?: boolean
  className?: string
  classNameWrapper?: string
  limit?: number
  destructiveText?: string
  variant?: 'unstyled' | 'outline'
  position?: 'top' | 'bottom'
  defaultValue?: string | number | undefined
  value?: string | number
  onChange?: (value?: string | number) => void
  placeholder?: string
  isExpand: boolean
  setIsExpand: Dispatch<SetStateAction<boolean>>
  actions?: {
    components?: ReactNode
    leftComponents?: ReactNode
    onCancelText?: string
    onSubmit?: (value: string | number | undefined) => void
    onSubmitText?: string
    onCancel: () => void
  }
  maxHeight?: number
}

const TextAreaWithActions = forwardRef<
  HTMLTextAreaElement,
  TextAreaWithActionsProps
>((props, ref) => {
  const {
    autoFocus,
    defaultValue = undefined,
    value,
    onChange,
    className = '',
    classNameWrapper = '',
    destructiveText = '',
    placeholder = '',
    limit = 500,
    variant = 'outline',
    position = 'bottom',
    isExpand,
    setIsExpand,
    actions,
    maxHeight = 200
  } = props

  useEffect(() => {
    if (isExpand === false && textareaRef?.current) {
      textareaRef.current.style.height = 'inherit'
    } else {
      autoFocus && textareaRef?.current?.focus()
      //move pointer to the end of line
      autoFocus &&
        value &&
        textareaRef?.current?.setSelectionRange(
          String(value).length,
          String(value).length
        )
    }
  }, [isExpand])

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    const height = 45

    if (textareaRef?.current) {
      textareaRef.current.style.height = '0px'
      if (textareaRef.current.scrollHeight <= maxHeight) {
        const scrollHeight = textareaRef.current.scrollHeight
        const calHeight = scrollHeight >= height - 9 ? scrollHeight : height - 9
        textareaRef.current.style.height = calHeight + 'px'
      } else {
        textareaRef.current.style.height = maxHeight + 'px'
      }
    }
  }, [maxHeight, textareaRef, value])

  return (
    <div className={cn('relative w-full bg-white', classNameWrapper)}>
      <div
        className={cn(
          textareaWithActionsWrapperVariants({
            variant,
            position,
            expand: isExpand ? 'expand' : 'default'
          })
        )}>
        <div className={cn(isExpand ? '' : 'flex h-[48px] items-center')}>
          <textarea
            ref={textareaRef}
            onClick={() => setIsExpand(true)}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            className={cn(
              textareaWithActionsVariants({
                expand: isExpand ? 'expand' : 'default',
                className
              }),
              isExpand && destructiveText ? 'mb-8' : ''
            )}
            onChange={(e) => {
              if (e.target.value === '' && textareaRef.current) {
                textareaRef.current.style.height = 'inherit'
              }
              onChange && onChange(e.target.value)
            }}
          />

          {isExpand && destructiveText ? (
            <div className="absolute left-0 right-0 -mt-7 flex items-center px-3">
              <div className="min-w-[16px]">
                <AlertCircleFill />
              </div>

              <div className="ml-1">
                <p className="text-sm font-normal text-red-500 dark:text-red-500">
                  {destructiveText}
                </p>
              </div>
            </div>
          ) : null}
        </div>

        {isExpand ? (
          <div className="min-h-[24px] absolute bottom-[60px] left-0 right-0 z-10 px-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                {actions?.components ? actions.components : null}
              </div>
            </div>
          </div>
        ) : null}

        <div
          className={cn(
            textareaActionsVariants({
              position,
              expand: isExpand ? 'expand' : 'default'
            })
          )}>
          <div>
            {isExpand && actions?.leftComponents
              ? actions.leftComponents
              : null}
          </div>

          <div className="flex items-center space-x-2">
            {isExpand ? (
              <Button
                size="xs"
                type="secondary"
                label={actions?.onCancelText || 'Cancel'}
                onClick={actions?.onCancel}
              />
            ) : null}

            <Button
              size="xs"
              type="primary"
              label={actions?.onSubmitText || 'Create'}
              onClick={() => {
                if (actions?.onSubmit) {
                  actions.onSubmit(value)
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

TextAreaWithActions.displayName = 'TextAreaWithActions'
export { TextAreaWithActions }

export type { TextAreaWithActionsProps }
