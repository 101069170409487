const MutationBulkUploadFile = `
  mutation ($files: [File!]!, $jobId: Int!) {
    profilesBulkCvsUpload(
      input: {
        files: $files
        jobId: $jobId
      }
    ) {
      profiles {
        id
        warning
        applicants {
          id
        }
      }
    }
  }
`

export default MutationBulkUploadFile
