import { StateCreator } from 'zustand'

export interface UploadSlice {
  openUploadDrawer: boolean
  setOpenUploadDrawer: (open: boolean) => void
  uploadDrawerJobId?: number
  setUploadDrawerJobId: (jobId?: number) => void
  isShowFullDrawer?: boolean
  setShowFullDrawer: (showFull: boolean) => void
}

export const createUploadSlice: StateCreator<
  UploadSlice,
  [],
  [],
  UploadSlice
> = (set: Function) => ({
  openUploadDrawer: false,
  setOpenUploadDrawer: (open: boolean) => set({ openUploadDrawer: open }),
  uploadDrawerJobId: undefined,
  setUploadDrawerJobId: (jobId?: number) => set({ uploadDrawerJobId: jobId }),
  isShowFullDrawer: false,
  setShowFullDrawer: (showFull: boolean) => set({ isShowFullDrawer: showFull })
})
