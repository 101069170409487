import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import DiscardChangeView from '~/components/DiscardChangeView/DiscardChangeView'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import configuration from '~/configuration'
import { IRouterWithID } from '~/core/@types/global'
import { Button } from '~/core/ui/Button'
import { ChoiceChips } from '~/core/ui/ChoiceChips'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { IFormAction } from '~/core/ui/Form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { RichEditor } from '~/core/ui/RichEditor'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { InterviewFeedbackDetailProps } from '~/lib/features/calendar/types'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import QueryAddSubmitFeedbackJobInterviewKits from '~/lib/features/settings/interview-kits/graphql/submit-add-submit-feedback-job-interview-kits'
import QueryUpdateSubmitFeedbackJobInterviewKits from '~/lib/features/settings/interview-kits/graphql/submit-update-submit-feedback-job-interview-kits'
import { mappingDataSubmitFeedbackJobInterviewKits } from '~/lib/features/settings/interview-kits/mapping/submit-feedback-job-interview-kits'
import schemaSubmitFeedbackJobInterviewKitsForm from '~/lib/features/settings/interview-kits/schema/submit-feedback-job-interview-kits-form'
import {
  IFormStateSubmitFeedbackInterviewKitTemplate,
  ISubmitFeedbackInterviewKitForm
} from '~/lib/features/settings/interview-kits/types'
import { useCatchAndScrollErrorForm } from '~/lib/hooks/use-catch-and-scroll-error-form'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import { InterviewFeedbackPermissionContext } from '../InterviewFeedbackView'
import InterviewFeedbackMetricAndQuestionForm from './InterviewFeedbackMetricAndQuestionForm'

interface InterviewFeedbackFormViewProps {
  interviewsShow?: InterviewFeedbackDetailProps
  interviewId?: IRouterWithID
  callback?: () => void
  callbackReFetch?: () => void
  isDrawer: boolean
}

const InterviewFeedbackFormView = (props: InterviewFeedbackFormViewProps) => {
  const { interviewsShow, interviewId, callback, callbackReFetch, isDrawer } =
    props
  const { t } = useTranslation()
  const { trigger: triggerCreate, isLoading: isLoadingCreate } =
    useSubmitCommon(QueryAddSubmitFeedbackJobInterviewKits)
  const { trigger: triggerUpdate, isLoading: isLoadingUpdate } =
    useSubmitCommon(QueryUpdateSubmitFeedbackJobInterviewKits)
  const triggerIntervalSubmit = useRef(false)
  const { formWrapper, handleCatchErrorForm } = useCatchAndScrollErrorForm()
  const { setToast } = useBoundStore()
  const interviewFeedbackPermission = useContext(
    InterviewFeedbackPermissionContext
  )

  const defaultValue = useMemo(
    () =>
      mappingDataSubmitFeedbackJobInterviewKits({
        source: interviewsShow?.currentUserFeedback || interviewsShow?.jobIkit,
        interviewId,
        keepStatus: false
      }),
    [interviewId, interviewsShow]
  )

  const onFinishCallback = useCallback(
    async (data: ISubmitFeedbackInterviewKitForm, formAction: IFormAction) => {
      if (interviewsShow?.currentUserFeedback) {
        if (isLoadingUpdate) {
          return
        }

        triggerUpdate({
          ...data,
          overallFeedback: data.overallFeedback?.[0],
          interviewId: undefined,
          jobIkitId: undefined
        }).then((result) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.settings.interviewKits,
              formAction,
              setToast
            })
          }

          const { interviewIkitFeedbacksUpdate } = result.data
          if (interviewIkitFeedbacksUpdate?.jobIkitEvaluation) {
            if (
              data.status === 'publish' &&
              triggerIntervalSubmit.current === false
            ) {
              callback && callback()
              setToast({
                open: true,
                type: 'success',
                title: `${t('notification:feedbackUpdated')}`
              })
            } else {
              triggerIntervalSubmit.current = false
              callbackReFetch && callbackReFetch()

              // const mappingData = mappingDataSubmitFeedbackJobInterviewKits({
              //   source: interviewIkitFeedbacksUpdate?.jobIkitEvaluation,
              //   interviewId,
              //   keepStatus: true
              // })

              // if (mappingData) {
              //   formAction.reset()
              //   for (const [key, value] of Object.entries<any>(mappingData)) {
              //     formAction.setValue(
              //       key as keyof ISubmitFeedbackInterviewKitForm,
              //       value
              //     )
              //   }
              // }
            }
          }

          return true
        })
      } else {
        if (isLoadingCreate) {
          return
        }

        triggerCreate({
          ...data,
          overallFeedback: data.overallFeedback?.[0],
          id: undefined
        }).then((result) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.settings.interviewKits,
              formAction,
              setToast
            })
          }

          const { interviewIkitFeedbacksCreate } = result.data
          if (interviewIkitFeedbacksCreate?.jobIkitEvaluation) {
            if (
              data.status === 'publish' &&
              triggerIntervalSubmit.current === false
            ) {
              callback && callback()
              setToast({
                open: true,
                type: 'success',
                title: `${t('notification:feedbackSubmitted')}`
              })
            } else {
              triggerIntervalSubmit.current = false
              callbackReFetch && callbackReFetch()

              const mappingData = mappingDataSubmitFeedbackJobInterviewKits({
                source: interviewIkitFeedbacksCreate?.jobIkitEvaluation,
                interviewId,
                keepStatus: true
              })

              if (mappingData) {
                formAction.reset()
                for (const [key, value] of Object.entries<any>(mappingData)) {
                  formAction.setValue(
                    key as keyof ISubmitFeedbackInterviewKitForm,
                    value
                  )
                }
              }
            }
          }

          return true
        })
      }
    },
    [
      interviewsShow?.currentUserFeedback,
      isLoadingUpdate,
      triggerUpdate,
      setToast,
      callback,
      callbackReFetch,
      isLoadingCreate,
      triggerCreate,
      interviewId
    ]
  )

  // ------- CALC HEIGHT -------
  const height = useClassBasedTopSpace({
    34: isDrawer === false ? 'calc(100vh - 91px)' : 'calc(100vh - 57px)',
    default: 'calc(100vh - 57px)'
  })
  const containerHeightClass = useClassBasedTopSpace({
    34:
      isDrawer === false ? 'h-[calc(100vh_-_91px)]' : 'h-[calc(100vh_-_57px)]',
    default: 'h-[calc(100vh_-_57px)]'
  })
  // ------- END CALC HEIGHT -------

  return (
    <DynamicImportForm
      isShowDebug={false}
      id="submit-feedback-form"
      defaultValue={defaultValue}
      schema={schemaSubmitFeedbackJobInterviewKitsForm(t)}
      onSubmit={onFinishCallback}
      className="h-full w-full">
      {({ formState, control, setValue, submit }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          const myInterval = setInterval(handleIntervalSubmit, 30000)
          return () => clearInterval(myInterval)
        }, [interviewsShow])

        const handleIntervalSubmit = () => {
          if (interviewsShow?.currentUserFeedback) {
            triggerIntervalSubmit.current = true
            submit && submit()
          }
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          handleCatchErrorForm(formState?.errors)
        }, [formState?.errors])

        return (
          <div
            className={cn(
              'relative flex min-h-full flex-col',
              containerHeightClass
            )}>
            {/* <DiscardChangeView isDirty={formState.isDirty} /> */}
            <ScrollArea
              scrollRef={formWrapper}
              rootStyle={{
                height: height
              }}>
              <div className="h-[60px] px-6 pt-4">
                <div className="flex items-center space-x-1">
                  <TypographyText className="text-base font-semibold text-gray-900">
                    {t('interview:feedback_modal:title')}
                  </TypographyText>
                  <Tooltip
                    content={t('tooltip:feedbackIsUsedInternally')}
                    mode="icon">
                    <IconWrapper
                      name="HelpCircle"
                      size={16}
                      className="text-gray-400"
                    />
                  </Tooltip>
                </div>
                <div className="mt-px">
                  <TypographyText className="text-xs font-normal text-gray-500">
                    {t('interview:feedback_modal:descriptionFeedback')}
                  </TypographyText>
                </div>
              </div>
              <div className="space-y-8 pt-4">
                <Controller
                  control={control}
                  name="ikitSessions"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InterviewFeedbackMetricAndQuestionForm
                        value={value || []}
                        onChange={onChange}
                        formState={
                          formState?.errors
                            ?.ikitSessions as IFormStateSubmitFeedbackInterviewKitTemplate
                        }
                      />
                    )
                  }}
                />
                <div className="space-y-5">
                  <div className="bg-gray-50 px-6 py-2.5">
                    <TypographyText className="text-sm font-medium text-gray-900">
                      {t('interview:feedback_modal:overallFeedback')}
                    </TypographyText>
                  </div>
                  <div className="px-6">
                    <div className="mb-4" id="form-name-overallFeedback">
                      <Controller
                        control={control}
                        name="overallFeedback"
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <FormControlItem
                            labelRequired
                            label={`${t('label:evaluation')}`}
                            destructive={
                              formState.errors &&
                              !!formState.errors?.overallFeedback
                            }
                            destructiveText={
                              formState.errors &&
                              (formState.errors?.overallFeedback
                                ?.message as string)
                            }>
                            <ChoiceChips
                              isDisabled={
                                !(
                                  interviewFeedbackPermission.create ||
                                  interviewFeedbackPermission.owned_update
                                )
                              }
                              value={value}
                              onChange={onChange}
                              selectOption="checkbox"
                              size="sm"
                              source={FEEDBACK_OPTIONS.map((item) => ({
                                ...item,
                                label: `${t(
                                  `candidates:feedback:${item.value}`
                                )}`
                              }))}
                            />
                          </FormControlItem>
                        )}
                      />
                    </div>

                    <div className="mb-6">
                      <Controller
                        control={control}
                        name="note"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <FormControlItem
                              label={`${t('label:note')}`}
                              destructive={
                                formState.errors && !!formState.errors?.note
                              }
                              destructiveText={
                                formState.errors &&
                                (formState.errors?.note?.message as string)
                              }>
                              <RichEditor
                                bubble
                                size="sm"
                                limit={50000}
                                className="w-full"
                                classNameWrapper="min-w-full w-full min-h-[96px]"
                                onChange={onChange}
                                content={value}
                                destructive={
                                  formState.errors && !!formState.errors?.note
                                }
                              />
                            </FormControlItem>
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>

            <div className="flex items-center justify-between border-t border-gray-100 px-6 py-4">
              <TypographyText className="text-sm text-gray-500">
                {isLoadingCreate || isLoadingUpdate
                  ? t('button:saving')
                  : interviewsShow?.currentUserFeedback?.id
                  ? t('button:autoSave')
                  : ''}
              </TypographyText>
              <If
                condition={
                  interviewFeedbackPermission.create ||
                  interviewFeedbackPermission.owned_update
                }>
                <div className="flex items-center space-x-3">
                  {!interviewsShow?.currentUserFeedback?.id ||
                  interviewsShow?.currentUserFeedback?.status === 'draft' ? (
                    <Button
                      size="sm"
                      type="secondary"
                      label={`${t('button:saveDraft')}`}
                      onClick={() => {
                        setValue('status', 'draft')
                        return submit && submit()
                      }}
                    />
                  ) : null}

                  <Button
                    isLoading={isLoadingCreate || isLoadingUpdate}
                    size="sm"
                    htmlType="button"
                    label={
                      interviewsShow?.currentUserFeedback &&
                      interviewsShow?.currentUserFeedback?.status !== 'draft'
                        ? `${t('button:updateFeedback')}`
                        : `${t('button:submitFeedback')}`
                    }
                    onClick={() => {
                      setValue('status', 'publish')
                      return submit && submit()
                    }}
                  />
                </div>
              </If>
            </div>
          </div>
        )
      }}
    </DynamicImportForm>
  )
}

export default InterviewFeedbackFormView
