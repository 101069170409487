const QuerySelectTenantMutation = `
  mutation ($tenantId: Int!) {
    selectTenant(
      input: {
        tenantId: $tenantId,
      }
    ) {
      currentTenant { id, name, logoVariants, phoneNumber, companySize, companyKind, description, careerSiteSettings, slug, tenantPlan { defaultTrial, name } }
      user { roles { id, name, code }, ownTenant, language }
    }
  }
`

export default QuerySelectTenantMutation
