import { useEffect, useMemo } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { useInfinityQuerySearch } from '~/lib/hooks/use-infinity-query-search'
import QueryTenantsList from '../graphql/query-tenants-list'
import { ITenantList } from '../types'

export function useQuerySelectCompany({
  variables
}: {
  variables: {
    page: number
    limit: number
  }
}) {
  const { clientGraphQL } = useContextGraphQL()

  const fetchData = async (params = {}) => {
    return clientGraphQL
      .query(QueryTenantsList, { ...params, search: undefined })
      .toPromise()
      .then((result: IResponseContextResult<ITenantList>) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.selectCompany
          })

          return {
            data: [],
            meta: {
              totalRowCount: 0,
              pageSize: configuration.defaultPageSize
            }
          }
        }

        const { tenantsList } = result.data
        const collection = tenantsList?.collection || []
        const metadata = tenantsList?.metadata || {}
        return {
          data: collection,
          meta: {
            totalRowCount: metadata.totalCount,
            pageSize: configuration.defaultPageSize
          }
        }
      })
  }
  const {
    data,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchedAfterMount,
    updateLocalRecord
  } = useInfinityQuerySearch<ITenantList>({
    configuration,
    fetchData,
    queryKey: variables
  })
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    trigger: refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    data: useMemo(
      () =>
        data?.pages.reduce<Array<ITenantList>>(
          (all, page) => [...all, ...page.data],
          []
        ),
      [data]
    ),
    totalRowCount: data?.pages[0]?.meta?.totalRowCount,
    updateLocalRecord
  }
}
