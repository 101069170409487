import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import AttachmentView from '~/components/AttachmentView'
import EducationView from '~/components/Candidates/Profile/components/Overview/Education/EducationView'
import WorkExperiencesView from '~/components/Candidates/Profile/components/Overview/WorkExperiences/WorkExperiencesView'
import HTMLDisplay from '~/components/HTMLDisplay'
import SkeletonContainer from '~/components/Skeleton'

import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { InlineEditingViewLink } from '~/core/ui/InlineEditing'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { isBrowser } from '~/core/utilities/is-browser'
import { ICandidateProfile } from '~/lib/features/candidates/types'
import { permittedFieldsManagement } from '~/lib/features/candidates/utilities'
import ProfileInformationViewOnly from './ProfileInformationViewOnly'

const RenderContactDetailItem = ({
  label,
  value,
  icon
}: {
  label: string
  value: string | ReactElement
  icon: ReactElement
}) => {
  return (
    <div className="grid grid-cols-[108px_1fr] desktop:grid-cols-[160px_1fr]">
      <div className="flex items-center space-x-2">
        {icon}
        <TypographyText className="text-sm text-gray-600">
          {label}
        </TypographyText>
      </div>
      <div>
        <div className="w-full">
          <div className="flex items-center px-2 py-1.5 text-sm">{value}</div>
        </div>
      </div>
    </div>
  )
}

const ProfileViewInFeedbackDetail: FC<{
  data?: ICandidateProfile
}> = ({ data }) => {
  const { t } = useTranslation()

  const permittedFields = data?.permittedFields

  return (
    <>
      <SkeletonContainer
        showMoreLabel={`${t('common:infinity:showMore')}`}
        useLoading={false}
        isFirstLoading={false}
        renderCustomSkeleton={
          <>
            {[1, 2, 3].map((item) => (
              <div key={`task-skeleton-${item}`}>
                <Skeleton className="mb-1.5 h-5 w-full rounded" />
                <Skeleton className="h-4 w-1/2 rounded" />
              </div>
            ))}
          </>
        }>
        <div className="">
          <div className="mb-1 flex items-center justify-between">
            <TypographyText className="text-lg font-medium text-gray-900">
              {t('candidates:tabs:candidateOverview:contactDetails:heading')}
            </TypographyText>
          </div>
          <RenderContactDetailItem
            label={t('candidates:tabs:candidateOverview:contactDetails:email')}
            icon={
              <IconWrapper
                size={16}
                name="Mail"
                className="hidden text-gray-500 desktop:block"
              />
            }
            value={
              data?.email && data?.email?.length > 0 ? (
                data.email[0]
              ) : (
                <div className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </div>
              )
            }
          />
          <RenderContactDetailItem
            label={t(
              'candidates:tabs:candidateOverview:contactDetails:phoneNumber'
            )}
            icon={
              <IconWrapper
                size={16}
                name="Phone"
                className="hidden text-gray-500 desktop:block"
              />
            }
            value={
              data?.phoneNumber && data.phoneNumber.length > 0 ? (
                data.phoneNumber[0]
              ) : (
                <div className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </div>
              )
            }
          />
          <RenderContactDetailItem
            label={t(
              'candidates:tabs:candidateOverview:contactDetails:location'
            )}
            icon={
              <IconWrapper
                size={16}
                name="MapPin"
                className="hidden text-gray-500 desktop:block"
              />
            }
            value={
              data?.permittedFields?.location?.value || (
                <div className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </div>
              )
            }
          />
          <RenderContactDetailItem
            label={t('candidates:tabs:candidateOverview:contactDetails:links')}
            icon={
              <IconWrapper
                size={16}
                name="Link"
                className="hidden text-gray-500 desktop:block"
              />
            }
            value={
              data?.links && Object.keys(data.links).length > 0 ? (
                <InlineEditingViewLink source={data.links} />
              ) : (
                <div className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </div>
              )
            }
          />
        </div>
        <If
          condition={
            permittedFieldsManagement(permittedFields?.skills?.roles) ||
            permittedFieldsManagement(
              permittedFields?.totalYearsOfExp?.roles
            ) ||
            permittedFieldsManagement(permittedFields?.languages?.roles) ||
            permittedFieldsManagement(permittedFields?.birthday?.roles) ||
            permittedFieldsManagement(permittedFields?.nationality?.roles)
          }>
          <div className="mt-6 space-y-1">
            <div className="flex items-center justify-between">
              <TypographyText className="text-lg font-medium text-gray-900">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:heading'
                )}
              </TypographyText>
            </div>
            <ProfileInformationViewOnly
              data={data || {}}
              configHide={{
                summary: true
              }}
            />
          </div>
        </If>
        <If
          condition={permittedFieldsManagement(
            permittedFields?.summary?.roles
          )}>
          <div className="mt-6 space-y-1">
            <div className="flex items-center justify-between">
              <TypographyText className="text-lg font-medium text-gray-900">
                {t('candidates:tabs:candidateOverview:summary:heading')}
              </TypographyText>
            </div>
            <div className="flex items-center text-xl">
              {data?.permittedFields?.summary?.value ? (
                <HTMLDisplay
                  content={data?.permittedFields?.summary?.value}
                  className="text-sm text-gray-900"
                />
              ) : (
                <TypographyText className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </TypographyText>
              )}
            </div>
          </div>
        </If>
        <div className="mt-6 space-y-1">
          <div className="flex items-center justify-between">
            <TypographyText className="text-lg font-medium text-gray-900">
              {t('candidates:tabs:candidateOverview:resumeCv:heading')}
            </TypographyText>
          </div>
          {(data?.profileCvs || []).length > 0 ? (
            (data?.profileCvs || []).map((group, i) => (
              <div key={i} className="min-h-full">
                {isBrowser() ? (
                  <AttachmentView
                    item={
                      group.attachments?.length
                        ? group.attachments[0]
                        : undefined
                    }
                  />
                ) : null}
              </div>
            ))
          ) : (
            <TypographyText className="text-sm text-gray-500">
              {t('candidates:tabs:candidateOverview:notAvailable')}
            </TypographyText>
          )}
        </div>
        <If
          condition={permittedFieldsManagement(
            permittedFields?.workExperiences?.roles
          )}>
          <div className="mt-6 space-y-1">
            <div className="flex items-center justify-between">
              <TypographyText className="text-lg font-medium text-gray-900">
                {t('candidates:tabs:candidateOverview:workExperiences:heading')}
              </TypographyText>
            </div>
            <div className="flex items-center text-xl">
              {permittedFields?.workExperiences?.value &&
              permittedFields?.workExperiences?.value.length ? (
                <WorkExperiencesView
                  source={permittedFields?.workExperiences.value}
                  isViewOnly
                />
              ) : (
                <TypographyText className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </TypographyText>
              )}
            </div>
          </div>
        </If>
        <If
          condition={permittedFieldsManagement(
            permittedFields?.educations?.roles
          )}>
          <div className="mt-6 space-y-1">
            <div className="flex items-center justify-between">
              <TypographyText className="text-lg font-medium text-gray-900">
                {t('candidates:tabs:candidateOverview:education:heading')}
              </TypographyText>
            </div>
            <div className="flex items-center text-xl">
              {permittedFields?.educations?.value &&
              permittedFields?.educations?.value?.length ? (
                <EducationView
                  isViewOnly
                  source={permittedFields?.educations.value}
                />
              ) : (
                <TypographyText className="text-sm text-gray-500">
                  {t('candidates:tabs:candidateOverview:notAvailable')}
                </TypographyText>
              )}
            </div>
          </div>
        </If>
      </SkeletonContainer>
    </>
  )
}

export default ProfileViewInFeedbackDetail
