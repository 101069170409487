import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, UseQueryExecute } from 'urql'
import { AlertDialog } from '~/core/ui/AlertDialog'
import MutationDeleteInterview from '~/lib/features/calendar/graphql/mutation-delete-interview'
import { useMoreLinkPopover } from '~/lib/features/calendar/hooks/use-get-more-link-popover'
import useBoundStore from '~/lib/store'

const InterviewDeleteModal: FC<{
  open: boolean
  onClose: () => void
  interviewId?: string | number
  eventType?: string
  beforeDeleteInterview?: () => void
  callbackDeleteInterview?: UseQueryExecute
}> = ({
  open,
  onClose,
  interviewId,
  eventType,
  beforeDeleteInterview,
  callbackDeleteInterview
}) => {
  const { t } = useTranslation()
  const [isSendMail, setIsSendMail] = useState<boolean>(false)
  const setToast = useBoundStore((state) => state.setToast)
  const { moreLinkPopover } = useMoreLinkPopover()

  const [{ fetching: deletingInterview }, deleteInterview] = useMutation(
    MutationDeleteInterview
  )

  const onDeleteInterview = useCallback(() => {
    beforeDeleteInterview && beforeDeleteInterview()

    const data = {
      id: Number(interviewId),
      sendEmail: isSendMail
    }

    deleteInterview(data).then((res) => {
      if (res?.data?.interviewsDelete?.success) {
        onClose()

        setToast({
          open: true,
          type: 'success',
          title: t('notification:interview:interviewDeleted')
        })
      }
      callbackDeleteInterview && callbackDeleteInterview()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId, isSendMail, deleteInterview, onClose])
  return (
    <div
      {...(!!moreLinkPopover
        ? { onMouseDown: (e) => e.stopPropagation() }
        : {})}>
      <AlertDialog
        open={open}
        destroy={onClose}
        title={`${t('common:modal:delete_this_interview')}`}
        description={
          <div
            className="text-sm text-gray-600"
            dangerouslySetInnerHTML={{
              __html: t('common:modal:delete_this_interview_description', {
                eventType
              })
            }}
          />
        }
        additionalCheckbox={{
          title: `${t('label:sendNotificationEmail')}`,
          isChecked: isSendMail,
          callback: (e) => {
            setIsSendMail(!!e.target.checked)
          }
        }}
        actions={[
          {
            label: `${t('button:cancel')}`,
            type: 'secondary',
            size: 'sm',
            onClick: onClose
          },
          {
            label: `${t('button:delete')}`,
            type: 'destructive',
            size: 'sm',
            isDisabled: deletingInterview,
            onClick: onDeleteInterview
          }
        ]}
      />
    </div>
  )
}

export default InterviewDeleteModal
