import { gql } from 'urql'

const QueryCandidateApplicantDetail = gql`
  query ($id: Int!) {
    applicantsShow(id: $id) {
      id
      createdAt
      jobStage {
        stageTypeId
        stageLabel
        stageGroup
      }
      job {
        company {
          contacts {
            firstName
            lastName
            email
          }
        }
        jobStages {
          id
          stageLabel
          stageTypeId
        }
      }
      overallFeedbacksCount
      pendingInterviewFeedbacks {
        id
        fromDatetime
        timezone
        eventTypeDescription
        attendees {
          id
        }
        job {
          owner {
            id
          }
          jobRecruiters {
            user {
              id
            }
          }
        }
      }
      profile {
        id
        fullName
        email
        headline
        phoneNumber
        address
        avatarVariants
        coverLetter
        countryStateId
        links
        sourced
        sourcedDescription
        sourcedName
        sourcedNameDescription
        createdAt
        profileCvs {
          id
          attachments {
            id
            file
            blobs
          }
        }
        jobs {
          id
          title
          jobStages {
            id
            stageLabel
            stageTypeId
          }
        }
        applicants {
          id
          coverLetter
          jobStage {
            stageGroup
          }
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
          overallFeedbacksCount
        }
        applicableJobs {
          id
          title
          jobLocations {
            state
            country
          }
          owner {
            id
            fullName
            email
          }
          department {
            name
          }
        }
        user {
          id
          fullName
          avatarVariants
          defaultColour
        }
        owner {
          id
          fullName
          avatarVariants
          defaultColour
        }
        totalYearsOfExp
        permittedFields
        tags {
          value
          name
          id
        }
        warning
        defaultAccessibleApplicantId
        customFields
        employeeId
      }
      placement {
        id
        status
        hiredDate
        onboardDate
        endOfProbationDate
        salary
        typeOfSalary
        currencyOfSalary
        fee
        typeOfFee
        revenue
        currencyOfRevenue
        applicant {
          id
          createdAt
          hiredDate
          jobStage {
            id
          }
          job {
            id
            title
          }
          profile {
            fullName
          }
        }
        hiredBy {
          id
          fullName
        }
        profitSplits {
          id
          profitPercentage
          user {
            id
            fullName
            avatarVariants
            defaultColour
          }
        }
        company {
          id
          name
        }
      }
    }
  }
`

export default QueryCandidateApplicantDetail
