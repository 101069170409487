import { cva } from 'class-variance-authority'
import { createRef } from 'react'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { TypographyText } from '~/core/ui/Text'
import { Textarea } from '~/core/ui/TextArea'
import { cn } from '~/core/ui/utils'
import {
  IFormStateSubmitFeedbackInterviewKitTemplate,
  IIkitSession
} from '~/lib/features/settings/interview-kits/types'

export const scoreActiveVariants = cva('text-white', {
  variants: {
    score: {
      1: 'border-red-500 bg-red-500',
      2: 'border-orange-500 bg-orange-500',
      3: 'border-yellow-500 bg-yellow-500',
      4: 'border-chart-800 bg-chart-800',
      5: 'border-green-500 bg-green-500'
    }
  },
  defaultVariants: {
    score: 1
  }
})

interface InterviewFeedbackMetricAndQuestionFormProps {
  value: IIkitSession[]
  onChange: (data: IIkitSession[]) => void
  formState?: IFormStateSubmitFeedbackInterviewKitTemplate
}

const InterviewFeedbackMetricAndQuestionForm = (
  props: InterviewFeedbackMetricAndQuestionFormProps
) => {
  const { value, onChange, formState } = props

  return (
    <>
      {value.map((item, index) => {
        return (
          <div key={`list-${index}`} className="space-y-5">
            <div className="bg-gray-50 px-6 py-2.5">
              <TypographyText className="text-sm font-medium text-gray-900">
                {item.name}
              </TypographyText>
            </div>

            <div className="space-y-4 px-6">
              {(item.metrics || []).map((metric, metricIndex) => {
                return (
                  <div
                    key={`metric-${metricIndex}`}
                    className="flex w-full justify-between space-x-4">
                    <TypographyText className="text-sm font-medium text-gray-600">
                      {metric.name}
                    </TypographyText>
                    <div className="-my-0.5 flex space-x-1">
                      {[1, 2, 3, 4, 5].map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            const cloneData = JSON.parse(
                              JSON.stringify(value || [])
                            )

                            cloneData[index].metrics[metricIndex].score = item
                            onChange(cloneData)
                          }}
                          className={cn(
                            'h-6 cursor-pointer rounded border px-2.5 py-[3px] text-xs',
                            metric.score && metric.score === item
                              ? scoreActiveVariants({
                                  score: metric.score
                                })
                              : 'border-gray-300 bg-white text-gray-600'
                          )}>
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="space-y-4 px-6">
              {(item.questions || []).map((question, questionIndex) => {
                return (
                  <div key={`question-${questionIndex}`} className="space-y-1">
                    <TypographyText className="text-sm font-medium text-gray-600">
                      {question.content}
                    </TypographyText>
                    <div className="w-full">
                      <FormControlItem
                        destructive={
                          !!formState?.[index]?.questions?.[questionIndex]
                            ?.answer?.message
                        }
                        destructiveText={
                          formState?.[index]?.questions?.[questionIndex]?.answer
                            ?.message
                        }>
                        <Textarea
                          ref={createRef()}
                          size="sm"
                          limit={1000}
                          showCount
                          rows={2}
                          rowsAutoExpanded={{
                            default: 50,
                            max: 140
                          }}
                          value={question.answer}
                          onChange={(valueText) => {
                            const cloneData = JSON.parse(
                              JSON.stringify(value || [])
                            )

                            cloneData[index].questions[questionIndex].answer =
                              valueText
                            onChange(cloneData)
                          }}
                          destructive={
                            !!formState?.[index]?.questions?.[questionIndex]
                              ?.answer?.message
                          }
                        />
                      </FormControlItem>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default InterviewFeedbackMetricAndQuestionForm
