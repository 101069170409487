import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { IParamsTableInfinity } from '~/core/@types/global'

export interface IPageResult<PageItem extends { id?: string }> {
  data: PageItem[]
  meta: { totalRowCount: number }
}

export const useInfinityQuerySearch = <
  PageItem extends { id?: string; subordinates?: PageItem[] }
>({
  configuration,
  fetchData,
  queryKey,
  enabled = true
}: {
  configuration: { defaultPageSize: number }
  fetchData: (pageParam: IParamsTableInfinity) => Promise<IPageResult<PageItem>>
  queryKey?: object
  enabled?: boolean
}) => {
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataUpdateRows, setDataUpdateRows] = useState<{
    [key: string]: PageItem
  }>({})
  const [listDeleteRows, setListDeleteRows] = useState<string[]>([])
  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    isLoading,
    isFetchedAfterMount,
    refetch,
    hasNextPage
  } = useInfiniteQuery(
    ['table-data', queryKey],
    async (pageParams): Promise<IPageResult<PageItem>> => {
      const getQueryKey = pageParams?.queryKey?.[1] as IParamsTableInfinity
      const params = {
        ...getQueryKey,
        page: pageParams.pageParam ? pageParams.pageParam + 1 : 1,
        search: getQueryKey?.search ? getQueryKey.search : globalFilter,
        key: undefined
      }
      const fetchedData = fetchData(params)
      return fetchedData
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      getPreviousPageParam: (firstPage, pages) => undefined,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled
    }
  )

  const updateLocalRecord = (dataUpdate: PageItem[] | PageItem) => {
    let isArray = Array.isArray(dataUpdate)
    let listUpdates = (isArray ? dataUpdate : [dataUpdate]) as PageItem[]
    listUpdates.forEach((item) =>
      setDataUpdateRows((dataUpdateRows) => ({
        ...dataUpdateRows,
        [item.id as string]: item
      }))
    )
  }
  const deleteLocalRecord = (id: string) => {
    setListDeleteRows((listDeleteRows) => [...listDeleteRows, id])
  }

  const result = useMemo<
    InfiniteData<IPageResult<PageItem>> | undefined
  >(() => {
    if (!data) return undefined
    return {
      ...data,
      pages: data.pages.map((group) => {
        return {
          data: (group?.data || [])
            .map((item) =>
              item.id
                ? {
                    ...(dataUpdateRows[item.id] || item),
                    ...(dataUpdateRows[item.id]?.subordinates ||
                    item?.subordinates
                      ? {
                          subordinates:
                            dataUpdateRows[item.id]?.subordinates ||
                            item?.subordinates
                        }
                      : {})
                  }
                : item
            )
            .filter((item) =>
              item.id ? !listDeleteRows.includes(item.id) : false
            )
            .map((item) => {
              return item.subordinates
                ? {
                    ...item,
                    subordinates: item.subordinates?.filter(
                      (fi) => !listDeleteRows.includes(fi.id || '')
                    )
                  }
                : item
            }),
          meta: {
            ...group.meta,
            totalRowCount: group.meta.totalRowCount - listDeleteRows.length
          }
        }
      })
    }
  }, [listDeleteRows, dataUpdateRows, data])
  const flatData = useMemo(
    () => result?.pages?.flatMap((page) => page.data) ?? [],
    [result]
  )
  const totalFetched = flatData.length
  return {
    data: result,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    isLoading,
    isFetchedAfterMount,
    hasNextPage,
    refetch: useCallback(() => {
      return refetch().then((rs) => {
        setDataUpdateRows({})
        setListDeleteRows([])
        return rs
      })
    }, [refetch]),
    globalFilter,
    setGlobalFilter,
    isEndListData: data?.pages?.[0]?.meta?.totalRowCount === totalFetched,
    updateLocalRecord,
    deleteLocalRecord,
    flatData,
    totalRowCount: data?.pages[0].meta.totalRowCount
  }
}
