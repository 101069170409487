import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import configuration from '~/configuration'
import { useInfinityGraphPage } from '../../jobs/hooks/use-infinity-graph-page'
import DeleteTaskMutation from '../graphql/delete-task-mutation'
import QueryTasksList from '../graphql/query-tasks-list'
import UpdateTaskMutation from '../graphql/update-task-mutation'
import { ITasksManagementFilter } from '../types'
import { trimObjectProps } from '../utilities/common'
import { TAB_KEYS } from '../utilities/enum'

const TABS_CACHE_KEYS: {
  [key: string]: {
    [key: string]: string
  }
} = {
  assigned: {
    default: 'tasks-management-list-assigned',
    completed: 'tasks-management-list-assigned-completed'
  },
  my: {
    default: 'tasks-management-list-my',
    completed: 'tasks-management-list-my-completed'
  }
}

const useTasksManagement = () => {
  const [filterValue, onChangeFilter] = useState<ITasksManagementFilter>({
    tab: TAB_KEYS[0].value
  })

  const queryKey = useMemo(
    () =>
      filterValue?.tab
        ? TABS_CACHE_KEYS?.[filterValue?.tab]?.[
            filterValue?.filterBy || 'default'
          ]
        : TABS_CACHE_KEYS.my.default,
    [filterValue?.filterBy, filterValue?.tab]
  )

  const taskPaging = useInfinityGraphPage({
    queryDocumentNote: QueryTasksList,
    getVariable: useCallback(
      (page) => {
        const { tab, profileId, filterBy } = filterValue || {}
        return trimObjectProps({
          limit: configuration.defaultPageSize,
          page,
          tab: tab || TAB_KEYS[0].value,
          ...(filterBy ? { filterBy: filterBy } : {}),
          ...(profileId ? { profileId: profileId } : {})
        })
      },
      [filterValue]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.tasksList?.metadata.totalCount,
      pageLength: groups[0].tasksList?.collection.length
    }),
    queryKey: [queryKey]
  })

  useEffect(() => {
    taskPaging.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  const [{ fetching: deletingTask }, deleteTask] =
    useMutation(DeleteTaskMutation)
  const [{ fetching: updatingTask }, updateTask] =
    useMutation(UpdateTaskMutation)

  return {
    taskPaging,
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      taskDeleteAction: {
        deleteTask: (args: { id: number; profileId?: number }) =>
          deleteTask(args),
        deletingTask
      },
      updateTaskAction: {
        updateTask: (args: {
          id: number
          title?: string
          profileId?: number
          dueDate?: string
          assigneeIds?: Array<number>
        }) => updateTask(args),
        updatingTask,
        updateTaskStatus: (args: {
          id: number
          status?: string
          title?: string
          assigneeIds?: Array<number>
        }) => updateTask(args)
      }
    }
  }
}

export default useTasksManagement
