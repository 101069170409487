import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import { IUserInformation } from '~/core/@types/global'
import { Avatar } from '~/core/ui/Avatar'
import { Badge } from '~/core/ui/Badge'
import IconWrapper from '~/core/ui/IconWrapper'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { useQuerySelectCompany } from '~/lib/features/select-company/hooks/use-query-select-company'

const SelectCompanyDropdown: FC<{
  submitDataCallback: (id: number) => Promise<void>
  user: IUserInformation
}> = ({ submitDataCallback, user }) => {
  const { t } = useTranslation()
  const { data: listTenants } = useQuerySelectCompany({
    variables: {
      page: 1,
      limit: 99
    }
  })

  return (
    <ScrollArea className="max-h-[314px] overflow-y-auto">
      <div className="p-1 outline-none">
        {listTenants?.map((item, index) => {
          const isSelectedCompany = item.id === user?.currentTenant?.id

          return (
            <button
              key={index}
              type="button"
              disabled={item.blocked || isSelectedCompany}
              onClick={() => submitDataCallback(Number(item.id))}
              className={`relative flex w-full items-center justify-between rounded px-3 py-[9px] ${
                !(item.blocked || isSelectedCompany)
                  ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700'
                  : 'cursor-default'
              }`}>
              <div className="flex flex-1 items-center">
                <div className="mr-3">
                  <Avatar
                    size="sm"
                    shape="rounded"
                    defaultAvatar={false}
                    src={item?.logoVariants?.thumb?.url}
                    alt={item?.name}
                  />
                </div>
                <div>
                  {item.blocked ? (
                    <Badge radius="circular" size="sm" color="gray">
                      {t('common:sidebar:blocked')}
                    </Badge>
                  ) : isSelectedCompany ? (
                    <Tooltip content={item.name}>
                      <TypographyText className="line-clamp-1 text-left text-sm font-medium text-gray-900">
                        {item?.name}
                      </TypographyText>
                    </Tooltip>
                  ) : (
                    <Tooltip content={item.name}>
                      <TypographyText className="line-clamp-1 text-left text-sm text-gray-500">
                        {item?.name}
                      </TypographyText>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div>
                {item.blocked ? (
                  <Badge radius="circular" size="sm" color="gray">
                    {t('common:sidebar:blocked')}
                  </Badge>
                ) : isSelectedCompany ? (
                  <IconWrapper name="Check" size={20} />
                ) : null}
              </div>
            </button>
          )
        })}
      </div>
    </ScrollArea>
  )
}

export default withQueryClientProvider(SelectCompanyDropdown)
