import { formatDistanceToNowStrict } from 'date-fns'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import LongContentDisplay from '~/components/LongContentDisplay'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { IRouterWithID } from '~/core/@types/global'
import { Avatar } from '~/core/ui/Avatar'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { defaultFormatDate } from '~/core/utilities/format-date'
import {
  InterviewDetailType,
  InterviewFeedbackDetailProps
} from '~/lib/features/calendar/types'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import { checkShowFeedbackInterview } from '~/lib/features/candidates/utilities'
import useInterviewStore from '~/lib/features/interviews/store'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import useBoundStore from '~/lib/store'
import InterviewFeedbackFormView from './Form/InterviewFeedbackFormView'
import { scoreActiveVariants } from './Form/InterviewFeedbackMetricAndQuestionForm'
import { InterviewFeedbackPermissionContext } from './InterviewFeedbackView'

interface InterviewFeedbackRightViewProps {
  isDrawer: boolean
  interviewsShow?: InterviewFeedbackDetailProps
  interviewId?: IRouterWithID
  callback?: () => void
  onClose?: () => void
}

const InterviewFeedbackRightView = (props: InterviewFeedbackRightViewProps) => {
  const { t } = useTranslation()
  const { isDrawer, interviewsShow, interviewId, callback, onClose } = props
  const { user, currentRole } = useBoundStore()
  const { interviewDrawer, setInterviewDrawer } = useInterviewStore()
  const { canAccessFullPermissionJobIkitEvaluation } = usePermissionJob()
  const [openForm, setOpenForm] = useState(false)
  const isNotOpenForm = !openForm && !interviewDrawer?.openForm
  const interviewFeedbackPermission = useContext(
    InterviewFeedbackPermissionContext
  )

  // ------- CALC HEIGHT -------
  const height = useClassBasedTopSpace({
    34: isDrawer === false ? 'calc(100vh - 91px)' : 'calc(100vh - 57px)',
    default: 'calc(100vh - 57px)'
  })
  const containerHeightClass = useClassBasedTopSpace({
    34:
      isDrawer === false ? 'h-[calc(100vh_-_91px)]' : 'h-[calc(100vh_-_57px)]',
    default: 'h-[calc(100vh_-_57px)]'
  })
  // ------- END CALC HEIGHT -------

  return interviewsShow ? (
    <div className="w-[38.6%] border-l border-gray-100">
      {checkShowFeedbackInterview({
        type: 'page',
        user,
        currentRole,
        item: interviewsShow as InterviewDetailType,
        permission: canAccessFullPermissionJobIkitEvaluation
      }) ? (
        <>
          {(checkShowFeedbackInterview({
            type: 'layout',
            user,
            currentRole,
            item: interviewsShow as InterviewDetailType,
            permission: canAccessFullPermissionJobIkitEvaluation
          }) ||
            interviewDrawer?.jobIkitEvalId) &&
          isNotOpenForm ? (
            <div
              className={cn(
                containerHeightClass,
                'relative flex min-h-full flex-col'
              )}>
              <ScrollArea
                rootStyle={{
                  height: height
                }}>
                <div className="px-6 pt-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-1">
                      <TypographyText className="text-base font-semibold text-gray-900">
                        {t('interview:feedback_modal:title')}
                      </TypographyText>
                      <Tooltip
                        content={t('tooltip:feedbackIsUsedInternally')}
                        mode="icon">
                        <IconWrapper
                          name="HelpCircle"
                          size={16}
                          className="text-gray-400"
                        />
                      </Tooltip>
                    </div>
                    <div className="flex space-x-1.5">
                      {(interviewsShow?.ikitFeedbacksSummary || []).map(
                        (item: Array<string | number>, index: number) => {
                          const feedbackFilter = FEEDBACK_OPTIONS.filter(
                            (s) => s.value === item[0]
                          )
                          return (
                            <Badge
                              key={`${item[0]}-${index}`}
                              variant="outline"
                              size="md"
                              radius="rounded"
                              iconSVG={feedbackFilter[0].iconMenus}
                              type="iconLeading">
                              {item?.[1]}
                            </Badge>
                          )
                        }
                      )}
                    </div>
                  </div>
                  <div className="mt-px">
                    <TypographyText className="text-xs font-normal text-gray-500">
                      {t('interview:feedback_modal:descriptionFeedback')}
                    </TypographyText>
                  </div>
                  {interviewsShow?.pendingFeedbackUsers?.length ? (
                    <div className="mt-2 flex">
                      <div className="w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none">
                          <path
                            d="M7.96603 9.69324C5.66604 9.69324 3.7254 10.0691 3.7254 11.5196C3.7254 12.9707 5.67857 13.3333 7.96603 13.3333C10.266 13.3333 12.2067 12.9575 12.2067 11.507C12.2067 10.0559 10.2535 9.69324 7.96603 9.69324Z"
                            fill="#9CA3AF"
                          />
                          <path
                            d="M7.96604 8.31131C9.52345 8.31131 10.7722 7.05534 10.7722 5.48897C10.7722 3.922 9.52345 2.66663 7.96604 2.66663C6.40864 2.66663 5.15985 3.922 5.15985 5.48897C5.15985 7.05534 6.40864 8.31131 7.96604 8.31131Z"
                            fill="#D4DAE0"
                          />
                          <path
                            d="M14.0587 6.14615C14.4616 4.56117 13.2803 3.1377 11.776 3.1377C11.6124 3.1377 11.456 3.15571 11.3032 3.18633C11.2829 3.19113 11.2602 3.20133 11.2483 3.21935C11.2346 3.24216 11.2447 3.27278 11.2596 3.29259C11.7115 3.93018 11.9712 4.70646 11.9712 5.53978C11.9712 6.33827 11.733 7.08273 11.3152 7.70051C11.2722 7.76415 11.3104 7.85001 11.3862 7.86321C11.4913 7.88182 11.5987 7.89143 11.7085 7.89443C12.8039 7.92325 13.7871 7.21421 14.0587 6.14615Z"
                            fill="#D4DAE0"
                          />
                          <path
                            d="M15.2062 9.87793C15.0056 9.44807 14.5215 9.15328 13.7855 9.00859C13.4381 8.92334 12.4979 8.80327 11.6234 8.81948C11.6103 8.82128 11.6031 8.83028 11.6019 8.83629C11.6001 8.84469 11.6037 8.8591 11.621 8.86811C12.0251 9.06923 13.5873 9.94397 13.3909 11.7889C13.3826 11.8688 13.4464 11.9378 13.5258 11.9258C13.9103 11.8706 14.8994 11.6568 15.2062 10.991C15.3757 10.6392 15.3757 10.2303 15.2062 9.87793Z"
                            fill="#9CA3AF"
                          />
                          <path
                            d="M4.69655 3.18645C4.54434 3.15523 4.38734 3.13782 4.22378 3.13782C2.71951 3.13782 1.53817 4.5613 1.9417 6.14628C2.21271 7.21433 3.19586 7.92337 4.29124 7.89456C4.40107 7.89155 4.50912 7.88135 4.61358 7.86334C4.68939 7.85013 4.7276 7.76427 4.68462 7.70063C4.26676 7.08225 4.02858 6.33839 4.02858 5.5399C4.02858 4.70599 4.28885 3.92971 4.74073 3.29271C4.75505 3.2729 4.7658 3.24228 4.75147 3.21947C4.73953 3.20086 4.71745 3.19125 4.69655 3.18645Z"
                            fill="#D4DAE0"
                          />
                          <path
                            d="M2.21439 9.00834C1.47837 9.15303 0.994852 9.44782 0.794282 9.87768C0.624155 10.2301 0.624155 10.639 0.794282 10.9914C1.10111 11.6566 2.09023 11.8709 2.47465 11.9255C2.55405 11.9376 2.61732 11.8691 2.60896 11.7887C2.41257 9.94432 3.97475 9.06958 4.37947 8.86846C4.39619 8.85885 4.39977 8.84504 4.39798 8.83604C4.39679 8.83003 4.39022 8.82103 4.37709 8.81983C3.50198 8.80302 2.5624 8.92309 2.21439 9.00834Z"
                            fill="#9CA3AF"
                          />
                        </svg>
                      </div>
                      <div className="ml-1.5 flex">
                        <TypographyText className="whitespace-nowrap text-xs text-gray-600">
                          {t('interview:feedback_modal:pending')}:
                        </TypographyText>
                        <div className="ml-1 flex items-center">
                          <TypographyText className="text-xs font-medium text-gray-900">
                            {interviewsShow.pendingFeedbackUsers
                              .map((item) => item.fullName)
                              .join(', ')}
                          </TypographyText>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {interviewsShow?.userFeedbacks?.ikitSessions?.length ? (
                  <div className="space-y-8 pt-4">
                    {interviewsShow.userFeedbacks.ikitSessions.map(
                      (item, index) => (
                        <div
                          key={`user-feedback-${index}`}
                          className="space-y-5">
                          <div className="flex justify-between bg-gray-50 px-6 py-2.5">
                            <TypographyText className="text-sm font-medium text-gray-900">
                              {item.name}
                            </TypographyText>

                            {item.users?.length ? (
                              <div className="flex space-x-2">
                                {item.users.map((user, index) => (
                                  <div key={index} className="w-7">
                                    <Avatar
                                      size="sm"
                                      color={user?.defaultColour}
                                      alt={user?.fullName}
                                      src={user?.avatar}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>

                          <div className="space-y-4 px-6">
                            {(item.metrics || []).map(
                              (
                                metric: {
                                  name: string
                                  userScores?: Array<{
                                    userId: number
                                    score?: 1 | 2 | 3 | 4 | 5
                                  }>
                                },
                                metricIndex
                              ) => {
                                return (
                                  <div
                                    key={`metric-${metricIndex}`}
                                    className="flex w-full justify-between space-x-4">
                                    <TypographyText className="text-sm font-medium text-gray-600">
                                      {metric.name}
                                    </TypographyText>

                                    {metric.userScores?.length ? (
                                      <div className="flex space-x-2">
                                        {metric.userScores.map(
                                          (userScore, index) => (
                                            <div
                                              key={`score-user-${index}`}
                                              className={cn(
                                                'h-6 w-7 rounded bg-red-500 px-[10px] py-[3px] text-xs',
                                                userScore.score
                                                  ? scoreActiveVariants({
                                                      score: userScore.score
                                                    })
                                                  : 'border border-gray-300 bg-white text-gray-500'
                                              )}>
                                              {userScore.score || '-'}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              }
                            )}
                          </div>

                          <div className="space-y-4 px-6">
                            {(item.questions || []).map(
                              (
                                question: {
                                  content: string
                                  answers?: Array<{
                                    userId: number
                                    answer?: string
                                    updatedAt: string
                                  }>
                                },
                                questionIndex
                              ) => {
                                return (
                                  <div
                                    key={`question-${questionIndex}`}
                                    className="space-y-2">
                                    <TypographyText className="text-sm font-medium text-gray-600">
                                      {question.content}
                                    </TypographyText>

                                    {question.answers?.filter((s) => s.answer)
                                      ?.length ? (
                                      <>
                                        {question.answers.map(
                                          (answer, index) => {
                                            const itemUser = (
                                              item.users || []
                                            ).filter(
                                              (item) =>
                                                String(item.id) ===
                                                String(answer.userId)
                                            )?.[0]
                                            return (
                                              <div
                                                key={`user-answer-${index}`}
                                                className="rounded bg-gray-50 px-3 py-2">
                                                <div
                                                  key={`overall-feedback-${index}`}
                                                  className="flex space-x-3">
                                                  <div className="py-1.5">
                                                    <Avatar
                                                      size="md"
                                                      color={
                                                        itemUser?.defaultColour
                                                      }
                                                      alt={itemUser?.fullName}
                                                      src={itemUser?.avatar}
                                                    />
                                                  </div>
                                                  <div className="flex-1 space-y-1">
                                                    <div className="flex items-center justify-between space-x-2">
                                                      <div className="flex space-x-2">
                                                        <Tooltip
                                                          align="start"
                                                          content={
                                                            itemUser?.fullName
                                                          }>
                                                          <TypographyText className="line-clamp-1 text-sm text-gray-900">
                                                            {itemUser?.fullName}
                                                          </TypographyText>
                                                        </Tooltip>
                                                      </div>
                                                      {answer?.updatedAt ? (
                                                        <Tooltip
                                                          align="end"
                                                          content={defaultFormatDate(
                                                            new Date(
                                                              answer.updatedAt
                                                            )
                                                          )}>
                                                          <TypographyText className="whitespace-nowrap text-sm text-gray-500">
                                                            {formatDistanceToNowStrict(
                                                              new Date(
                                                                answer.updatedAt
                                                              )
                                                            )}
                                                          </TypographyText>
                                                        </Tooltip>
                                                      ) : null}
                                                    </div>
                                                    {answer?.answer ? (
                                                      <LongContentDisplay
                                                        limitLines={3}
                                                        content={answer.answer}
                                                        className="max-w-full flex-1 whitespace-pre-line text-sm text-gray-900"
                                                        textButtonProps={{
                                                          size: 'md'
                                                        }}
                                                      />
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <TypographyText className="text-sm text-gray-500">
                                        {t(
                                          'interview:feedback_modal:noAnswers'
                                        )}
                                      </TypographyText>
                                    )}
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null}

                {interviewsShow?.userFeedbacks?.overallFeedbacks?.length ? (
                  <div className="space-y-4 py-4">
                    <div className="bg-gray-50 px-6 py-2.5">
                      <TypographyText className="text-sm font-medium text-gray-900">
                        {t('interview:feedback_modal:overallFeedback')}
                      </TypographyText>
                    </div>
                    <div className="space-y-4 px-6">
                      {interviewsShow.userFeedbacks.overallFeedbacks.map(
                        (item, index) => {
                          const feedbackFilter = FEEDBACK_OPTIONS.filter(
                            (s) => s.value === item.overallFeedback
                          )
                          return (
                            <div
                              key={`overall-feedback-${index}`}
                              className={cn(
                                'flex space-x-3',
                                item?.note ? '' : 'items-center'
                              )}>
                              <div className="py-1.5">
                                <Avatar
                                  size="md"
                                  color={item?.user?.defaultColour}
                                  alt={item?.user?.fullName}
                                  src={item?.user?.avatar}
                                />
                              </div>
                              <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between space-x-2">
                                  <div className="flex space-x-2">
                                    <Tooltip
                                      align="start"
                                      content={item?.user?.fullName}>
                                      <TypographyText className="line-clamp-1 text-sm font-medium text-gray-900">
                                        {item?.user?.fullName}
                                      </TypographyText>
                                    </Tooltip>

                                    <Badge
                                      variant="outline"
                                      size="md"
                                      radius="rounded"
                                      classNameText="truncate"
                                      iconSVG={feedbackFilter[0].iconMenus}
                                      type="iconLeading">
                                      {t(
                                        `candidates:feedback:${feedbackFilter?.[0]?.value}`
                                      )}
                                    </Badge>
                                  </div>
                                  {item?.updatedAt ? (
                                    <Tooltip
                                      align="end"
                                      content={defaultFormatDate(
                                        new Date(item.updatedAt)
                                      )}>
                                      <TypographyText className="whitespace-nowrap text-sm text-gray-500">
                                        {formatDistanceToNowStrict(
                                          new Date(item.updatedAt)
                                        )}
                                      </TypographyText>
                                    </Tooltip>
                                  ) : null}
                                </div>
                                <HTMLDisplay
                                  className="text-sm text-gray-900"
                                  content={item?.note}
                                />
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </div>
                ) : null}
              </ScrollArea>

              {checkShowFeedbackInterview({
                type: 'button',
                user,
                currentRole,
                item: interviewsShow as InterviewDetailType,
                permission: canAccessFullPermissionJobIkitEvaluation
              }) ||
              interviewsShow.currentUserFeedback ||
              isDrawer ? (
                <div
                  className={cn(
                    'flex items-center border-t border-gray-100 px-6 py-4',
                    isDrawer ? 'justify-between' : 'justify-end'
                  )}>
                  {checkShowFeedbackInterview({
                    type: 'button',
                    user,
                    currentRole,
                    item: interviewsShow as InterviewDetailType,
                    permission: canAccessFullPermissionJobIkitEvaluation
                  }) || interviewsShow.currentUserFeedback ? (
                    <If
                      condition={
                        interviewFeedbackPermission.create ||
                        interviewFeedbackPermission.owned_update
                      }>
                      <Button
                        size="sm"
                        type="secondary"
                        label={
                          interviewsShow?.currentUserFeedback?.status ===
                          'draft'
                            ? `${t('button:submitFeedback')}`
                            : `${t('button:editYourFeedback')}`
                        }
                        onClick={() => setOpenForm(true)}
                      />
                    </If>
                  ) : null}
                  {isDrawer ? (
                    <Button
                      size="sm"
                      label={`${t('button:close')}`}
                      onClick={onClose}
                      className="ml-auto"
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : (
            <InterviewFeedbackFormView
              isDrawer={isDrawer}
              interviewsShow={interviewsShow}
              interviewId={interviewId}
              callbackReFetch={() => {
                setOpenForm(false)
                setInterviewDrawer({
                  ...interviewDrawer,
                  openForm: false
                })
                callback && callback()
              }}
              callback={onClose}
            />
          )}
        </>
      ) : null}
    </div>
  ) : null
}

export default InterviewFeedbackRightView
