'use client'

import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  createRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import Cropper, { Point } from 'react-easy-crop'
import { Avatar, AvatarProps, AvatarSize } from '~/core/ui/Avatar'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { AlertCircleFill } from '~/core/ui/FillIcons'
import { Slider } from '~/core/ui/Slider'
import { convertFileSizeToBytes } from '~/lib/schema'

const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

function rotateSize({
  width,
  height,
  rotation
}: {
  width: number
  height: number
  rotation: number
}) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  }
}

async function getCroppedImg({
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
}: {
  imageSrc: string
  pixelCrop: { x: number; y: number; width: number; height: number }
  rotation?: number
  flip?: { horizontal: boolean; vertical: boolean }
}) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize({
    width: image.width,
    height: image.height,
    rotation
  })

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg')

  const cropImage = await new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file ? resolve(file) : resolve('')
    }, 'image/jpeg')
  })

  // As a blob
  return {
    cropImage,
    cropImageBase64: canvas.toDataURL('image/jpeg')
  }
}

interface UploadImageDialogProps {
  open?: boolean
  modal?: boolean
  className?: string
  title?: string
  destroy: () => void
  onCropDataCallback?: ({
    image,
    imageBase64
  }: {
    image: Object
    imageBase64: string
  }) => void
  image: string
  aspectRatio?: number
  configText?: {
    [string: string]: string
  }
}

const UploadImageDialog: FC<UploadImageDialogProps> = ({
  open = false,
  modal = true,
  className = '',
  title = '',
  image,
  aspectRatio = 1,
  destroy,
  onCropDataCallback,
  configText
}) => {
  const cropperRef = createRef<any>()
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropData = useCallback(() => {
    if (typeof cropperRef.current?.getCropData() !== 'undefined') {
      const { croppedAreaPixels } = cropperRef.current?.getCropData()

      getCroppedImg({
        imageSrc: image,
        pixelCrop: croppedAreaPixels
      }).then((result) => {
        const { cropImage, cropImageBase64 } = result as {
          cropImage: object
          cropImageBase64: string
        }

        onCropDataCallback &&
          cropImage &&
          onCropDataCallback({
            image: cropImage,
            imageBase64: cropImageBase64
          })
      })
    }
  }, [cropperRef, image, onCropDataCallback])

  useEffect(() => {
    cropperRef.current?.computeSizes() // compute crop area size when first load
  }, [cropperRef])

  return (
    <Dialog
      open={open}
      onOpenChange={destroy}
      isPreventAutoFocusDialog={true}
      modal={modal}
      label={title}
      className={` w-full max-w-[343px] tablet:w-[480px] tablet:max-w-[480px] ${className}`}
      headingClassName="border-b border-b-gray-300 tablet:py-5">
      <div className="pt-6">
        <div className="z-1 relative mb-5 h-[235px] overflow-hidden">
          <div className="absolute inset-x-0 bottom-20 top-0 h-full">
            <Cropper
              ref={cropperRef}
              image={image}
              crop={crop}
              zoom={zoom}
              showGrid={false}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              objectFit="auto-cover"
            />
          </div>
        </div>
        <div className="mb-6">
          <Slider
            defaultValue={[zoom]}
            min={1}
            max={3}
            step={0.1}
            onValueChange={(value) => setZoom(value[0])}
          />
        </div>

        <div className="flex justify-end">
          <Button
            configurations="default"
            className="mr-3"
            label={configText?.cancel || "Cancel"}
            onClick={destroy}
            size="sm"
            type="secondary"
          />
          <Button
            configurations="default"
            label={configText?.cropImage || "Crop Image"}
            onClick={onCropData}
            size="sm"
          />
        </div>
      </div>
    </Dialog>
  )
}

const validatorImageFileSize = ({
  file,
  maximumSize = '10MB'
}: {
  file: File
  maximumSize?: string
}) => {
  //validate file size
  const maximumFileSizeInBytes = convertFileSizeToBytes({ size: maximumSize })
  const fileSize = file?.size
  return maximumFileSizeInBytes > fileSize
}

interface UploadImageProps {
  type?: 'default' | 'avatar-only'
  dialogCropperTitle?: string
  value?: string
  maximumSize?: string
  onChange?: (value: Object) => void
  onChangeBase64?: (value: string) => void
  shape?: 'rounded' | 'circular'
  size?: AvatarSize
  color?: string
  alt?: string
  avatarConfig?: AvatarProps
  defaultAvatar?: boolean
  callbackGotErrorUploadImage?: (
    error_type: 'invalid_file_size' | 'invalid_file_type'
  ) => void
  configText?: {
    [string: string]: string
  },
  isDisabled?: boolean
  aspectRatio?: number
}

const ACCEPT_IMAGE_FILE_TYPES = `image/jpeg, image/png, image/jpg`
const UploadImage: FC<UploadImageProps> = ({
  type = 'default',
  dialogCropperTitle,
  value = '',
  maximumSize = '10MB', // default image size
  onChange,
  onChangeBase64,
  shape = 'rounded',
  size,
  color,
  alt,
  defaultAvatar = true,
  avatarConfig = {},
  callbackGotErrorUploadImage,
  configText,
  isDisabled,
  aspectRatio = 1
}) => {
  const uploadImageRef = createRef<HTMLInputElement>()
  const [openCropDialog, setOpenCropDialog] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [cropImage, setCropImage] = useState<string | undefined>(value)
  const [valueState, setValueState] = useState<string>(value)
  const onInputImageFileChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      event.preventDefault()
      const files = event.target ? event.target.files : []

      if (files) {
        //validate
        const isValidFileType = [
          'image/jpeg',
          'image/png',
          'image/jpg'
        ].includes(files[0].type)
        const isValidFileSize = validatorImageFileSize({
          file: files[0],
          maximumSize
        })

        if (!isValidFileSize) {
          callbackGotErrorUploadImage &&
            callbackGotErrorUploadImage('invalid_file_size')
          return setErrors(['invalid_file_size'])
        } else if (!isValidFileType) {
          callbackGotErrorUploadImage &&
            callbackGotErrorUploadImage('invalid_file_type')
          return setErrors(['invalid_file_type'])
        } else setErrors([])

        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            setCropImage(reader.result as string)
          },
          false
        )
        reader.readAsDataURL(files[0])

        if (isValidFileType) {
          setOpenCropDialog(true)
        } else {
          setErrors([])
        }

        return () => reader.removeEventListener('load', () => {})
      }
    },
    [maximumSize, callbackGotErrorUploadImage]
  )

  const onClickUpload: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.currentTarget.value = ''
    },
    []
  )

  const onCropDataCallback = useCallback(
    ({ image, imageBase64 }: { image?: Object; imageBase64?: string }) => {
      image && onChange && onChange(image)
      imageBase64 && onChangeBase64 && onChangeBase64(imageBase64)
      imageBase64 && setValueState && setValueState(imageBase64)
      setOpenCropDialog(false)
    },
    [onChange, onChangeBase64]
  )

  const onUploadButtonClick = useCallback(() => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click()
    }
  }, [uploadImageRef])

  const onCloseCropDialog = useCallback(() => {
    if (uploadImageRef.current) {
      uploadImageRef.current.value = ''
    }
    setOpenCropDialog(false)
  }, [uploadImageRef])

  if (type === 'avatar-only') {
    return (
      <>
        <input
          disabled={isDisabled}
          ref={uploadImageRef}
          type="file"
          className="hidden"
          accept={ACCEPT_IMAGE_FILE_TYPES}
          onChange={onInputImageFileChange}
          onClick={onClickUpload}
        />

        <div className="flex items-center">
          <Avatar
            typeUpload
            src={valueState}
            shape={shape}
            onClick={onUploadButtonClick}
            size={size}
            {...avatarConfig}
          />
        </div>

        {cropImage && (
          <UploadImageDialog
            open={openCropDialog}
            destroy={onCloseCropDialog}
            title={dialogCropperTitle}
            onCropDataCallback={onCropDataCallback}
            image={cropImage}
            aspectRatio={aspectRatio}
            configText={configText}
          />
        )}
      </>
    )
  }

  return (
    <>
      <input
        disabled={isDisabled}
        ref={uploadImageRef}
        type="file"
        className="hidden"
        accept={ACCEPT_IMAGE_FILE_TYPES}
        onChange={onInputImageFileChange}
        onClick={onClickUpload}
      />
      <div className="flex items-center">
        <div className="mr-4">
          <Avatar
            color={color}
            alt={alt}
            src={valueState}
            shape={shape}
            onClick={onUploadButtonClick}
            defaultAvatar={defaultAvatar}
            {...avatarConfig}
          />
        </div>

        <div className="">
          <div>
            <Button
              configurations="default"
              label={configText?.upload || "Upload"}
              htmlType="button"
              onClick={onUploadButtonClick}
              size="xs"
              type="secondary"
            />
          </div>
          {errors.length > 0 ? (
            <div className="mt-1.5 flex items-center">
              <div className="mr-1.5 ">
                <AlertCircleFill />
              </div>

              <p className="text-sm text-red-500">
                {configText?.supportedFormat || `Only supported png, jpg, jpeg. Maximum size is ${maximumSize}`}
              </p>
            </div>
          ) : (
            <p className="mt-1.5 text-sm text-gray-500">
              {configText?.supportedFormat || `Only supported png, jpg, jpeg. Maximum size is ${maximumSize}`}
            </p>
          )}
        </div>
      </div>

      {cropImage && (
        <UploadImageDialog
          open={openCropDialog}
          destroy={onCloseCropDialog}
          title={dialogCropperTitle}
          onCropDataCallback={onCropDataCallback}
          image={cropImage}
          aspectRatio={aspectRatio}
          configText={configText}
        />
      )}
    </>
  )
}

export { UploadImage }
export type { UploadImageProps }
