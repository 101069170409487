import configuration from '~/configuration'
import {
  IPromiseSearchOption,
  IRouterWithID,
  ISelectOption
} from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IMember } from '~/lib/features/settings/members/types'
import useBoundStore from '~/lib/store'
import QueryAgencyTenantMembers from '../graphql/query-tenant-members'

interface IMembersListProps {
  everyoneOption?: ISelectOption
  avatarSize?: string
  currentUserAsMe?: boolean
  companyId?: number
  applicantId?: IRouterWithID
  candidateProfileId?: IRouterWithID
}

const useQueryMembersList = (props: IMembersListProps) => {
  const {
    everyoneOption,
    avatarSize,
    currentUserAsMe = false,
    companyId,
    candidateProfileId,
    applicantId
  } = props
  const { clientGraphQL } = useContextGraphQL()
  const user = useBoundStore((state) => state.user)

  const promiseMemberOptions = (params = {} as IPromiseSearchOption) =>
    clientGraphQL
      .query(QueryAgencyTenantMembers, {
        ...params,
        ...(companyId ? { companyId: Number(companyId) } : {}),
        ...(candidateProfileId && !applicantId
          ? { profileId: Number(candidateProfileId) }
          : undefined),
        ...(applicantId ? { applicantId: Number(applicantId) } : undefined)
      })
      .toPromise()
      .then((result: IResponseContextResult<IMember>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { tenantMembers } = result.data
        const collection = tenantMembers?.collection || []
        const metadata = tenantMembers?.metadata || {}
        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            ...(avatarSize ? { avatarSize } : {}),
            supportingObj: {
              name:
                currentUserAsMe && Number(item.id) === Number(user.id)
                  ? 'Me'
                  : item.fullName,
              description: item.email,
              defaultColour: item.defaultColour
            }
          }
        })
        const mergeData =
          params.page === 1
            ? everyoneOption
              ? [everyoneOption, ...cloneData]
              : cloneData
            : cloneData

        return {
          metadata: { ...metadata, totalCount: metadata.totalCount + 1 },
          collection: mergeData
        }
      })
  return {
    promiseMemberOptions,
    optionMemberDefault: everyoneOption
  }
}
export default useQueryMembersList
